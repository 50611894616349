<template>
  <div class="k-checkbox" :class="classes">
    <span class="k-checkbox__input-wrapper">
      <input type="checkbox"
        class="k-checkbox__input"
        :class="{'k-checkbox__input--dash': dash}"
        v-bind="$attrs"
        :id="checkboxId"
        :value="value"
        :checked="shouldBeChecked"
        :disabled="disabled"
        @change="updateInput"/>
      <span class="k-checkbox__state">
        <template v-if="dash && !shouldBeChecked"></template>
        <template v-else></template>
      </span>
    </span>
    <label class="k-checkbox__label" :for="checkboxId">
      <slot></slot>
      <div class="k-checkbox__hint" v-if="$slots.hint">
        <slot name="hint"></slot>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'k-checkbox',

  model: {
    prop: 'modelValue',
    event: 'change',
  },

  props: {
    id: {
      type: String,
    },
    value: {
      default: '',
    },
    modelValue: {
      default: false,
    },
    trueValue: {
      default: true,
    },
    falseValue: {
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dash: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    shouldBeChecked() {
      if (Array.isArray(this.modelValue)) {
        return this.modelValue.indexOf(this.value) > -1;
      }
      return this.modelValue === this.trueValue;
    },

    checkboxId() {
      // TODO: better id gen for elements.
      // this is a unique-ish ID assuming we have less a few hundred
      // implicitly id'd radio buttons on the page.
      return this.id || `checkbox-${Math.random(1000)}${Date.now()}`;
    },

    classes() {
      return {
        'k-checkbox--disabled': this.disabled,
      };
    },
  },

  methods: {
    updateInput(event) {
      const isChecked = event.target.checked;

      if (Array.isArray(this.modelValue)) {
        const newArr = [...this.modelValue];
        const valueIndex = newArr.indexOf(this.value);
        if (valueIndex > -1) {
          newArr.splice(valueIndex, 1);
        } else {
          newArr.push(this.value);
        }
        this.$emit('change', newArr, this.value);
      } else {
        this.$emit('change', isChecked ? this.trueValue : this.falseValue);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .k-checkbox {
    position: relative;
    display: flex;
    align-items: flex-start;

    &__input-wrapper {
      position: relative;
    }

    &__input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: 0;
    }

    &__label {
      display: flex;
      flex-direction: column;
      padding-left: var(--grid-unit);
      margin: 0;
      font-weight: unset;
      vertical-align: middle;
      cursor: pointer;
      user-select: none;
    }

    &__hint {
      max-width: 65ch;
      color: var(--gray-50);
    }

    &__state {
      display: inline-block;
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      margin-top: calc(-1 * var(--grid-unit) / 2);
      font-family: "Font Awesome 5 Pro";
      font-size: 0;
      font-weight: bold;
      line-height: 18px;
      color: var(--gray-0);
      vertical-align: middle;
      pointer-events: none;
      user-select: none;
      background-color: var(--gray-0);
      border: 2px solid var(--gray-80);
      border-radius: calc(var(--radius) / 2);
      transition: background-color 150ms ease-in, font-size 150ms ease-in;
    }

    &:not(:last-child) {
      margin-bottom: calc(var(--grid-unit) / 2);
    }
  }

  .k-checkbox__input--dash + .k-checkbox__state,
  .k-checkbox__input:checked + .k-checkbox__state {
    font-size: 11px;
    color: var(--gray-0);
    text-align: center;
    background-color: var(--gray-80);
  }

  .k-checkbox--disabled {
    cursor: not-allowed;

    .k-checkbox__input,
    .k-checkbox__label {
      cursor: not-allowed;
    }

    .k-checkbox {
      /* stylelint-disable-next-line no-descending-specificity */
      &__state { border-color: var(--gray-30); }
      &__label { color: var(--gray-50); }

      &__input:checked + .k-checkbox__state {
        background-color: var(--gray-30);
      }
    }
  }
</style>
